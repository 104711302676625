import "plyr-react/plyr.css";
import React from "react";
import PlyrComponent from './custom-hls-player.tsx';


const App = () => {
  // const hlsVideoURL = 'https://d3or6nrh1caqds.cloudfront.net/assets/b4d26fa3-ae90-4256-9eca-b7d02b665a93/HLS/Kantara.m3u8';
  // const playerRef = useRef(null);



  // useEffect(() => {
  //   if (playerRef.current) {
  //     const player = playerRef.current.plyr;
  //     const hls = new Hls();
  //     hls.loadSource(hlsVideoURL);
  //     hls.attachMedia(player.media);
  //     hls.on(Hls.Events.MANIFEST_PARSED, () => {
  //       player.play();
  //     });
  //   }
  // }, [playerRef]);


  return (
    <div className="App">
      <PlyrComponent />
    </div>
  );
};


export default App;